import React from "react"
import { Link as GLink } from "gatsby"

import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardContent from "@material-ui/core/CardContent"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { Paper, AppBar, Toolbar } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  gridContainer: {
    padding: theme.spacing(1, 1),
  },
  postGrid: {
    // margin: theme.spacing(1, 1)
    // padding: theme.spacing(1,5)
  },
  card: {
    // display: "flex",
  },
  cardDetails: {
    // flex: 1,
  },
  year: {
    margin: theme.spacing(5, 0),
  },
}))

const BlogList = ({ groups }) => {
  const classes = useStyles()
  return (
    <React.Fragment>
      {groups.map(group => {
        const yearPosts = group.edges
        return (
          <Paper key={group.fieldValue} className={classes.year}>
            <AppBar position="static">
              <Toolbar>
                <Typography id={group.fieldValue}>
                  {group.fieldValue}
                </Typography>
              </Toolbar>
            </AppBar>
            <Grid container spacing={1} className={classes.gridContainer}>
              {yearPosts.map(({ node }) => {
                const title = node.frontmatter.title || node.slug
                return (
                  <Grid
                    item
                    key={node.slug}
                    xs={6}
                    className={classes.postGrid}
                  >
                    <CardActionArea
                      component={GLink}
                      to={node.slug}
                      className={classes.card}
                    >
                      <Card key={node.slug} raised={true}>
                        <CardContent>
                          <Typography>{title}</Typography>
                          <small>{node.frontmatter.date}</small>
                          <p
                            dangerouslySetInnerHTML={{
                              __html:
                                node.frontmatter.description || node.excerpt,
                            }}
                          />
                          <Typography variant="subtitle1" color="primary">
                            Continue reading...
                          </Typography>
                        </CardContent>
                      </Card>
                    </CardActionArea>
                  </Grid>
                )
              })}
            </Grid>
          </Paper>
        )
      })}
    </React.Fragment>
  )
}

export default BlogList
