import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BlogList from "../templates/blog-list"

const BlogIndex = props => {
  const { data } = props
  const siteTitle = data.site.siteMetadata.title

  const groups = [...data.allBlogPost.group]
  groups.reverse()

  return (
    <Layout location={props.location} title={siteTitle}>
      <SEO title="All posts" />
      <h1
        style={{
          // TODO: set this
          // marginTop: rhythm(1),
          marginBottom: 0,
        }}
      >
        Blog
      </h1>
      <p>Everything here is ancient and probably not relevant for anything, but I didn't see any reason to remove it :)</p>
      <BlogList groups={groups} />
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    allBlogPost(sort: { fields: frontmatter___date, order: DESC }) {
      group(field: frontmatter___year) {
        fieldValue
        edges {
          node {
            excerpt
            slug
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              year: date(formatString: "YYYY")
              title
            }
          }
        }
      }
    }
  }
`
